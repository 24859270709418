/**
 * @license
 * Copyright 2016 Google Inc.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.neuroglancer-annotation-rendering-tab {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.neuroglancer-annotation-rendering-tab .neuroglancer-shader-code-widget {
  flex-shrink: 0;
}

.neuroglancer-annotation-shader-property-list {
  max-height: 8em;
  overflow: auto;
  flex-shrink: 0;
}

.neuroglancer-annotation-shader-property {
  white-space: pre;
  font-family: monospace;
  font-size: medium;
}

.neuroglancer-annotation-shader-property-type {
  color: #c6c;
  margin-right: 1ch;
}

.neuroglancer-annotation-shader-property-identifier {
  user-select: text;
}

.neuroglancer-annotation-shader-property-identifier::after {
  content: "()";
  color: #999;
}

.neuroglancer-annotation-tag-property-type {
  color: #999;
}

.neuroglancer-tag-list > div {
  display: grid;
}

.neuroglancer-add-tag-control > input,
.neuroglancer-tag-list-entry > input {
  background-color: #151515;
  color: white;
  font-family: monospace;
  font-size: medium;
  border: 2px solid #333;
  padding: 2px;
  outline: 0px;
}

/* copy of .neuroglancer-annotation-layer-view */
/* layer/annotation/style.css vs src/ui/annotations.css */
.neuroglancer-tags-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
}

.neuroglancer-tag-list-outer {
  position: relative;
  margin: 0px;
  padding: 0px;
  margin-top: 2px;
  overflow-y: auto;
  height: 0px;
  flex: 1;
  flex-basis: 0px;
  min-height: 0px;
}

.neuroglancer-tag-list-entry {
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  white-space: nowrap;
  padding: 2px 20px 2px 0;
}

.neuroglancer-tag-list-entry.add .neuroglancer-tool-button {
  visibility: hidden;
}

.neuroglancer-tag-list-entry:hover .neuroglancer-tag-list-entry-delete {
  visibility: visible;
}

.neuroglancer-tag-list-entry .neuroglancer-tag-list-entry-delete {
  visibility: hidden;
}